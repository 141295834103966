export default {
  proxy_path: "https://g4jn9ruiai.execute-api.ap-southeast-2.amazonaws.com/api",
  proxy_path_geojson_api: "https://3o68qug7v7.execute-api.ap-southeast-2.amazonaws.com/api",
  google_api_key: "AIzaSyBthLqIniCp2bAvxhf4mvKwBT0I6ENtM7M",
  main_company_term_id: "6",
  crm_base_url: "ozminerals.forwoodsafety.com",
  custom_tile_url: "https://mapoverlays.geoeditor.ozminerals.forwoodsafety.com",
  MAP_REPORT_ACCESS_ROLE: "MapReportAccess",
  forwoodId: {
    URL: "https://id.ozminerals.forwoodsafety.com",
    APP_CLIENT_ID: "2gmktkd1atohp2q0qvsa78vdbn",
  },
  apiGateway: {
    REGION: "ap-southeast-2",
    URL: "https://a5x449ve4d.execute-api.ap-southeast-2.amazonaws.com/prod",
  },
  reactApp: {
    HOSTNAME: "https://mapreport.ozminerals.forwoodsafety.com",
  },
  get forwoodIdAuthUrl() {
    return `${
      this.forwoodId.URL
    }/authorize?response_type=token&scope=openid&client_id=${
      this.forwoodId.APP_CLIENT_ID
    }&redirect_uri=${this.reactApp.HOSTNAME}&state=`;
  },
};
